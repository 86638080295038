import tailwindStylesheetUrl from "@/app/app.css?url";
import { Footer } from "@/app/components/footer";
import { GeneralErrorBoundary } from "@/app/components/general-error-boundary.tsx";
import { Header } from "@/app/components/header";
import { getEnv } from "@/app/lib/env.server.ts";
import * as gtag from "@/app/lib/gtag.client";
import { combineHeaders, getDomainUrl } from "@/app/lib/misc.tsx";
import { makeTimings, time } from "@/app/lib/timing.server";
import {
  type HeadersFunction,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  unstable_data as data,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { type ReactNode, useEffect } from "react";
import appleTouchIconAssetUrl from "./assets/favicons/apple-touch-icon.png";
import faviconAssetUrl from "./assets/favicons/favicon.svg";
import { getUser } from "./lib/supabase/server.server";

export const links: LinksFunction = () =>
  [
    {
      rel: "icon",
      href: "/favicon.ico",
      sizes: "48x48",
    },
    { rel: "icon", type: "image/svg+xml", href: faviconAssetUrl },
    { rel: "apple-touch-icon", href: appleTouchIconAssetUrl },
    {
      rel: "manifest",
      href: "/site.webmanifest",
      crossOrigin: "use-credentials",
    } as const,
    { rel: "stylesheet", href: tailwindStylesheetUrl },
  ].filter(Boolean);

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? "HelpSaúde" : "Erro | HelpSaúde" },
    {
      name: "description",
      content: "HelpSaúde: Seu portal de busca de profissionais de saúde",
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings("root loader");
  const user = await time(() => getUser(request), {
    timings,
    type: "find user",
    desc: "find user in root",
  });
  // const { toast, headers: toastHeaders } = await getToast(request);
  // const honeyProps = honeypot.getInputProps();

  return data(
    {
      user: user?.user,
      requestInfo: {
        // hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        // userPrefs: {
        //   theme: getTheme(request),
        // },
      },
      ENV: getEnv(),
      // toast,
      // honeyProps,
    },
    {
      headers: combineHeaders(
        { "Server-Timing": timings.toString() },
        // toastHeaders,
      ),
    },
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    "Server-Timing": loaderHeaders.get("Server-Timing") ?? "",
  };
  return headers;
};

function Document({
  children,
  env = {},
  allowIndexing = true,
  allowAds = true,
}: {
  children: ReactNode;
  env?: Record<string, string | undefined>;
  allowIndexing?: boolean;
  allowAds?: boolean;
}): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    if (env.GA_MEASUREMENT_ID?.length) {
      gtag.pageview(location.pathname, env.GA_MEASUREMENT_ID);
    }
  }, [location, env.GA_MEASUREMENT_ID]);

  return (
    <html lang="en">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        {allowIndexing ? null : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <meta content="HelpSaúde" name="author" />
        <meta content="HelpSaúde (2024)" name="copyright" />
        <meta content="#274fad" name="theme-color" />
        <meta content="light" name="color-scheme" />
        <meta content="Helpsaúde" name="application-name" />
        <Links />
      </head>
      <body>
        {process.env.NODE_ENV !== "development" && (
          <>
            {allowAds ? (
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9847031185780341"
                crossOrigin="anonymous"
              />
            ) : null}
            <script
              // nonce={nonce}
              async
              src={`https://metricas.helpsaude.com/gtm.js?id=${env.GA_MEASUREMENT_ID}`}
            />
            <script
              // nonce={nonce}
              async
              id="gtag-init"
              // biome-ignore lint/security/noDangerouslySetInnerHtml: <no other way to do this...>
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${env.GA_MEASUREMENT_ID}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        {children}
        <script
          // nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App(): JSX.Element {
  const data = useLoaderData<typeof loader>();
  const allowIndexing = data.ENV.ALLOW_INDEXING !== "false";
  const matches = useMatches();
  const isOnAdsPage = matches.some(
    (m) => m.id === "routes/_index" || m.id === "routes/$",
  );

  return (
    <Document
      allowIndexing={allowIndexing}
      allowAds={isOnAdsPage}
      env={data.ENV}
    >
      <Header user={data.user} />
      <Outlet />
      <Footer />
    </Document>
  );
}

export default withSentry(App);

export function ErrorBoundary(): JSX.Element {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    console.log("error.status", error.status);
    console.log("error.statusText", error.statusText);
    console.log("error.data", error.data);
  } else if (error instanceof Error) {
    console.log("error.message", error.message);
    console.log("error.stack", error.stack);
  } else {
    console.log("unknow error");
  }

  // the nonce doesn't rely on the loader so we can access that
  // const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.
  return (
    <html lang="en">
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="robots" content="noindex, nofollow" />
        <meta content="HelpSaúde" name="author" />
        <meta content="HelpSaúde (2024)" name="copyright" />
        <meta content="#274fad" name="theme-color" />
        <meta content="light" name="color-scheme" />
        <meta content="Helpsaúde" name="application-name" />
        <Links />
      </head>
      <body>
        <GeneralErrorBoundary />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
